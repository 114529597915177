<template>
  <div class="step-nav">
    <div
      class="step-item"
      :class="{ active: stepNum === index, after: stepNum > index }"
      v-for="(item, index) in navData"
      :key="index"
      @click="isClick && selectStep(index)"
    >
      {{ item }}
      <!-- <span class="arrow" icon="el-icon-s-home" v-if="index !== navData.length - 1"  > ></span> -->
      <el-link
        class="arrow"
        disabled
        icon="el-icon-arrow-right"
        v-if="index !== navData.length - 1"
      ></el-link>
    </div>

    <!-- <div 
             v-for="(item, index) in navData"
             :key="index"
      
            > -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    navData: {
      type: Array,
      required: true,
    },
    stepNum: {
      type: Number,
      default: 0,
    },
    isClick: {
      type: Boolean,
      value: false,
    },
  },
  methods: {
    selectStep(value) {
      this.$emit("changeStep", value);
    },
  },
};
</script>

<style lang="less" scoped>
.step-nav {
  margin-top: 30px;

  display: flex;

  .step-item {
    font-size: 16px;
    margin-right: 10px;
    width: 160px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border: 1px solid transparent;
    background: #f6f7fb;
    border-radius: 10px;
    position: relative;
    margin-right: 60px;
    color: black;
    &.active {
      color: #fff;
      border-color: transparent;
      background: #1889ff;
    }
    &.after {
      color: #2690fe;
      border-color: transparent;
      background: #edf6ff;
    }

    .arrow {
      font-size: 28px;
      color: #c4c8cc;
      position: absolute;
      right: -45px;
      display: inline-block;
      margin-right: 1px;
      cursor: default;
    }
  }
}
</style>